var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PrimePage',[_c('ion-content',[_c('div',{staticClass:"prime-view-container fixed-full-height"},[_c('div',{staticClass:"prime-full-width-container"},[_c('h3',{staticClass:"view-title"},[_vm._v("Broadcast Message History")]),(_vm.isPageLoading)?_c('div',{staticClass:"prime-empty-container margin-top-twenty"},[_c('ion-spinner',{attrs:{"name":"lines"}})],1):(!_vm.hasData)?_c('div',{staticClass:"prime-empty-container full-height"},[(!_vm.isTableLoading)?_c('div',[_c('IconCheckCircle',{staticClass:"prime-tasks-empty-icon icon-check-circle-watermark"}),_c('h5',[_vm._v("There are no previous Broadcast messages to view.")])],1):_c('div',{staticClass:"prime-empty-container margin-top-twenty"},[_c('ion-spinner',{attrs:{"name":"dots"}})],1)]):_c('div',{staticClass:"prime-tasks-section-container"},[(_vm.isTableLoading)?_c('div',{staticClass:"text-center pad-ten loading-overlay center-center fade-in margin-top-ten"},[_c('ion-spinner',{attrs:{"name":"dots"}})],1):_vm._e(),_c('vue-good-table',{ref:"vgt-table",attrs:{"mode":"remote","pagination-options":{
              enabled: true,
              mode: 'records',
              perPageDropdown: [10, 20, 30, 40, 50],
              setCurrentPage: _vm.page,
              perPage: _vm.perPage,
              dropdownAllowAll: false
            },"totalRows":_vm.totalRecords,"columns":_vm.columns,"rows":_vm.rows,"styleClass":"prime-tasks-table vgt-tasks-table","sort-options":{
              enabled: true
            }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.label == 'Subject')?_c('span',[_vm._v(" "+_vm._s(props.row.subject ? props.row.subject : "")+" ")]):_vm._e(),(props.column.label == 'Message')?_c('span',[_c('div',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(props.row.message ? props.row.message : "")+" ")])]):_vm._e(),(props.column.label == 'Sent By')?_c('span',[_vm._v(" "+_vm._s(_vm.makeProfessionalName(props.row))+" ")]):_vm._e(),(props.column.label == 'Sent')?_c('span',[(props.row.createdAt)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(props.row.createdAt,"M/DD/YYYY - h:mmA")))]):_c('span',[_vm._v("N/A")])]):_vm._e(),(props.column.label == 'Patients')?_c('span',[_c('button',{staticClass:"prime-button button-block button-secondary",on:{"click":function($event){return _vm.openDescriptionModal(props.row.id)}}},[_vm._v(_vm._s(props.row.count)+" Patients")])]):_vm._e()]}}])})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }