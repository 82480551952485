import { render, staticRenderFns } from "./BroadcastMessageHistory.vue?vue&type=template&id=48b3162b&scoped=true"
import script from "./BroadcastMessageHistory.vue?vue&type=script&lang=js"
export * from "./BroadcastMessageHistory.vue?vue&type=script&lang=js"
import style0 from "./BroadcastMessageHistory.vue?vue&type=style&index=0&id=48b3162b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b3162b",
  null
  
)

export default component.exports