<template>
  <div class="prime-modal column-stretch-wrap full-height">
    <div class="column-stretch-header bottom-divider">
      <ion-header>
        <ion-toolbar>
          <h5 class="text-bold margin-top-ten margin-bottom-ten">Broadcast Message</h5>
          <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
        </ion-toolbar>
      </ion-header>
    </div>
    <div class="column-stretch-body bottom-divider">
      <ion-row class="pad-lr-twenty full-height">
        <ion-col class="pad-right-twenty right-divider full-height overflow-y-scroll">
          <ion-row class="margin-top-ten">
            <ion-col>
              <div class="text-small text-muted">Sent By</div>
              <p class="text-bold">{{ sentBy }}</p>
            </ion-col>
            <ion-col>
              <div class="text-small text-muted">Sent</div>
              <p class="text-bold" v-if="createdAt">{{ createdAt | moment("M/DD/YYYY - h:mmA") }}</p>
            </ion-col>
          </ion-row>
          <ion-row class="pad-top-ten pad-bottom-ten">
            <ion-col>
              <div class="text-small text-muted">Subject</div>
              <div class="text-bold margin-top-ten">{{ subject ? subject : "" }}</div>
            </ion-col>
          </ion-row>
          <ion-row class="pad-top-ten pad-bottom-twenty">
            <div class="text-small text-muted margin-bottom-ten">Message</div>
            <div class="broadcast-message-description-balloon full-width" :class="balloonStyle">
              <div>
                {{ message }}
              </div>
            </div>
          </ion-row>
        </ion-col>
        <ion-col class="margin-left-twenty full-height overflow-y-scroll">
          <ion-row class="pad-top-ten pad-bottom-ten ion-align-items-center">
            <div v-if="patients.length > 0" class="pad-top-five scroll-vert pad-lr-five">
              <ion-card v-for="patient in patients" :key="patient.id" class="prime-patient-card margin-left-ten ion-margin-bottom full-width">
                <div class="avatar current-patient-avatar margin-right-ten" v-if="patient.beneFirstName && patient.beneLastName">
                  {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                </div>
                <div class="pad-fifteen">
                  <div class="text-bold">
                    {{ patient.beneLastName + ", " + patient.beneFirstName }}
                  </div>
                  <ion-col class="text-small text-muted margin-right-ten"><strong>ID: </strong>{{ patient.mrn }}</ion-col>
                  <ion-col class="text-small text-muted"><strong>PCP: </strong>{{ patient.pcpTitle | formatDisplayName(patient.pcpFirstName, patient.pcpLastName, patient.suffix) }}</ion-col>
                </div>
              </ion-card>
            </div>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
    <div class="column-stretch-footer">
      <ion-row class="ion-align-items-center pad-lr-twenty pad-top-ten pad-bottom-ten">
        <ion-col>
          <ion-row class="pad-ten ion-align-items-center">
            <ion-col>
              <button @click="sendNewBroadcastMessage(patients)" class="prime-button button-secondary button-block margin-right-ten">
                Send New Broadcast Message to These Patients
              </button>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col>
          <ion-row class="pad-ten ion-align-items-center">
            <ion-col>
              <button @click="closeModal()" class="prime-button button-primary button-block">
                Close
              </button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { more } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import router from "@/router";
import store from "@/store";

addIcons({
  "md-more": more.md
});
export default {
  name: "ModalBroadcastMessageDescription",
  props: {},
  components: {},
  created() {
    this.getBroadcastMessageDescription(this.broadcastMessageId);
  },
  data() {
    return {
      patients: [],
      subject: undefined,
      message: undefined,
      sentBy: undefined,
      createdAt: undefined,
      isLoadingDescription: false
    };
  },
  computed: {
    balloonStyle() {
      let color = this.color;
      let orientation = this.orientation;
      color = "blue";
      orientation = "right";

      return [color, orientation];
    }
  },
  methods: {
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    makeProfessionalName(rowObj) {
      let title = rowObj.pcpTitle;
      let fName = rowObj.pcpFirstName;
      let lName = rowObj.pcpLastName;
      let suffix = rowObj.pcpSuffix;
      return (title ? title : "") + (fName ? fName : "") + (lName ? " " + lName : "") + (suffix ? ", " + suffix : "");
    },
    sendNewBroadcastMessage(patients) {
      store.commit("patients/patients", patients);
      this.goToNamedRoute("broadcast-message");
      this.closeModal();
    },
    goToNamedRoute(routeName) {
      router.push({
        name: routeName
      });
    },
    getBroadcastMessageDescription(id) {
      this.isLoadingDescription = true;
      const method = "get";
      const path = document.config.broadcastMessageDescription + id;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.subject = result.data[0].subject;
          this.message = result.data[0].message;
          this.sentBy = this.makeProfessionalName(result.data[0]);
          this.createdAt = result.data[0].createdAt;
          this.patients = result.data;
        })
        .catch(error => {
          this.catchError(error, "Could not get broadcast message information. Please try again later.");
        })
        .finally(() => (this.isLoadingDescription = false));
    }
  }
};
</script>

<style scoped>
.broadcast-message-description-balloon.left {
  border-bottom-left-radius: 0;
}

.broadcast-message-description-balloon.white {
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
}

.broadcast-message-description-balloon.white a {
  color: var(--ion-color-primary);
}

.broadcast-message-description-balloon.right {
  border-bottom-right-radius: 0;
}

.broadcast-message-description-balloon.blue,
.broadcast-message-description-balloon.blue a {
  background-color: var(--ion-color-primary);
  color: #ffffff !important;
}
.broadcast-message-description-balloon .chat-info {
  margin-top: 13px;
}
.broadcast-message-description-balloon {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  min-width: 80%;
  padding: 15px;
  border-radius: 20px;
}
.chat-info {
  margin-top: 5px;
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}

.pad-lr-five {
  padding-right: 5px;
  padding-left: 5px;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
</style>
